/**
* Generated automatically at built-time (2024-10-07T17:14:50.715Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "cuisine-d-exterieur-guide-d-entretien",templateKey: "sites/104-2d7a8281-b7a5-44b2-a9c7-991eb821d07b"};