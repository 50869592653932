/**
* Generated automatically at built-time (2024-10-07T17:14:50.623Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "construire-une-cuisine-d-exterieur",templateKey: "sites/104-236822ad-5444-4b57-970d-6c0993a1e265"};